import { CSpinner } from "@coreui/react";

import "./pageLoader.css";
import { Fragment } from "react";

const PageLoader = (props) => {
      return (
          <div className="loader-container">
              {props.loading && (
                <CSpinner
                  size="sm"
                  style={{ width: "3rem", height: "3rem" }}
                  className="loader"
                />
              )}

              {!props.loading && ( <Fragment>{props.children}</Fragment>)}
          </div>  
      );
};

export default PageLoader;
