// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container{
    width: 100%;
    height: calc(100vh - 200px);
    position: relative;
    background-color: transparent;
    /* opacity: 0.7; */
    
    /* z-index: 2023; */
}

.loader{
    /* z-index: 3000; */
    opacity: 1;

    color:#2819af;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: translate(-50%,-50%);
}`, "",{"version":3,"sources":["webpack://./src/views/loader/pageLoader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2BAA2B;IAC3B,kBAAkB;IAClB,6BAA6B;IAC7B,kBAAkB;;IAElB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,UAAU;;IAEV,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC","sourcesContent":[".loader-container{\n    width: 100%;\n    height: calc(100vh - 200px);\n    position: relative;\n    background-color: transparent;\n    /* opacity: 0.7; */\n    \n    /* z-index: 2023; */\n}\n\n.loader{\n    /* z-index: 3000; */\n    opacity: 1;\n\n    color:#2819af;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transition: translate(-50%,-50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
